import React, { useEffect,useState,useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Snackbar, Alert,Box, Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoButton from './InfoButton';
import Fuse from 'fuse.js';

// Function to parse query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const GenerateProposalManual = ({
	projectTypes,
	data,
  currentUser,
  serverUrl,
  isGeneratingProposal,
  handleGenerateProposalManual,
  proposalResponse,
  isResponseModalOpen,
  setIsResponseModalOpen,
  projectTitle,
  setProjectTitle,
  projectDescription,
  setProjectDescription,
  copyToClipboard,
  isLoading,
  showUpworkLinkInput,
  hasPaid,
  showProfileBuilding,
  showUpworkTagsInput,
  snackbarMessage,
  openSnackbar,
  handleCloseSnackbar,basicPlan,infoLoaded
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const [selectReferencesManually, setSelectReferencesManually] = useState(false);
  const [selectApproachManually, setSelectApproachManually] = useState(false);
  const [selectedReferences, setSelectedReferences] = useState([]);
  const [selectedApproach, setSelectedApproach] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [projectcalled,setProjectcalled] = useState(false);
  const [useO1,setUseO1] = useState(false);
  const [selectStandardDescriptionManually,setSelectStandardDescriptionManually] = useState(false);
  const [selectedDescriptions,setSelectedDescriptions] = useState([]);
  
  const handleReferenceSelectionChange = (portfolioItem, isChecked) => {
	  console.log(isChecked);
	  let temp=[...selectedReferences]
    if (isChecked) {
		
		
      setSelectedReferences([...temp, portfolioItem.summary]);
    } else {
      setSelectedReferences(temp.filter(item => item !== portfolioItem.summary));
    }
	console.log(selectedReferences);
  };
  
  const handleDescriptionSelectionChange = (descriptionItem, isChecked) => {
	  console.log(isChecked);
	  let temp=[...selectedDescriptions]
    if (isChecked) {
		
		
      setSelectedDescriptions([...temp, descriptionItem.description]);
    } else {
      setSelectedDescriptions(temp.filter(item => item !== descriptionItem.description));
    }
	
  };
  useEffect(() => {
	  console.log("here1");
  if (showUpworkLinkInput) {
	  console.log("here2");

    navigate('/upwork-link');
  } else if (!hasPaid && infoLoaded) {
	  console.log("here3");

    navigate('/payment');
  } else if (showProfileBuilding) {
	  	  console.log("here4");
    navigate('/building-profile');
  } else if (showUpworkTagsInput || basicPlan) {
	  	  console.log("here5");

    navigate('/settings');
  }
  	  console.log("here6");

}, [showUpworkLinkInput, hasPaid, showProfileBuilding, showUpworkTagsInput, navigate,basicPlan]);

  useEffect(() => {
  const fuse = new Fuse(data, {
    keys: ['summary'],
    includeScore: true,
    threshold: 0.4,
    ignoreLocation: true,
    findAllMatches: true,
    useExtendedSearch: true,
  });

  const results = searchQuery ? fuse.search(searchQuery).map(result => result.item) : data;
  setFilteredData(results);

  if (!selectReferencesManually) {
    setSelectedReferences([]); // Clear selected references if manual selection is unchecked
  }
}, [data, searchQuery, selectReferencesManually]);

   useEffect(() => {

  if (!selectApproachManually) {
    setSelectedApproach(""); // Clear selected references if manual selection is unchecked
  }
}, [selectApproachManually]); 

   useEffect(() => {

  if (!selectStandardDescriptionManually) {
    setSelectedDescriptions([]); // Clear selected references if manual selection is unchecked
  }
}, [selectStandardDescriptionManually]); 
const fetchProject = useCallback(async () => {
	if (!projectcalled) {
		setProjectcalled(true);
  const projectId = query.get('projectId');
  if (!projectId) return;

  try {
	  function convertfromHTML(str) {
  
  var characters = [ "'"];
  var entities = [/&#039;/g];
  
  for(var i = 0; i < entities.length; i++) {
    str = str.replace(entities[i], characters[i]);
  }
  
  return str;
}
    const response = await currentUser.getIdToken(true).then(async function(idToken) {
      return await fetch(`${serverUrl}/project?projectId=${projectId}&idToken=${idToken}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Project');
    }

    const data = await response.json();
    if (data["Title"]) {
      setProjectTitle(data["Title"]);
      setProjectDescription(convertfromHTML(data["Description"].split('<b>Budget</b>')[0].split('<br />').join(" ")));
    } else {
      throw new Error('No project returned from server');
    }
  } catch (error) {
    console.error('Error fetching project:', error);
    navigate('/error'); // Handle error
  }
	}
}, []); // Include dependencies that could change

useEffect(() => {
  fetchProject();
}, []); // Triggered whenever fetchProject changes, which should only be when its dependencies change

 	  if (isGeneratingProposal) {
    return (
 <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>
          Generating Proposals can take 10-20 seconds. Please wait.
        </Typography>
      </Box>
    );
  }	

		  if (isLoading) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
    );
  }	
console.log("dislay")  
return (<Box sx={{ p: 2 }}>
  <InfoButton   content={
    <div>
      <Typography variant="body1" gutterBottom>
        On the <strong>Generate Proposal</strong> page, you can input your own project title and description to effortlessly generate a proposal tailored to the project's requirements and your experience.
      </Typography>
      
      <Typography variant="body1" gutterBottom>
        However, if your <strong>'Portfolio'</strong> section is empty or contains fewer than 10 items, this functionality may not be much better for you than a generic ChatGPT proposal.
      </Typography>
      
      <Typography variant="body1" gutterBottom>
        To enhance the quality of your proposals, go to the <strong>'Portfolio'</strong> tab and ensure you have listed at least 10 diverse projects.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Additionally, proposals become even more personalized when you define your project types in the <strong>'Settings'</strong> section.
      </Typography>
      
      <Typography variant="body1" gutterBottom>
        For example, you might want to use a different introduction for a <em>PowerPoint creation project</em> compared to a <em>translation project</em>.
      </Typography>

      <Typography variant="body1" gutterBottom>
        By defining specific intro texts for each type of project, such as <em>PowerPoints</em> and <em>Translations</em>, you can easily choose the most relevant one and craft a highly tailored proposal.
      </Typography>
    </div>
  }/>
  
  <Grid container spacing={2}>
    {/* Input fields for title and description */}
    <Grid item xs={12}>
      <TextField
        label="Project Title"
        variant="outlined"
        value={projectTitle}
        onChange={(e) => setProjectTitle(e.target.value)}
        fullWidth
			  sx={{
				 mb: 2 ,
    input: { color: 'white' }, // Change the input text color to white
    label: { color: 'white' }, // Change the label text color to white
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Change the border color to white
      },
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color to white when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color to white when focused
      },
    },
  }}
        
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Project Description"
        variant="outlined"
        value={projectDescription}
        onChange={(e) => setProjectDescription(e.target.value)}
        fullWidth
        multiline
        rows={4}
        sx={{
				 mb: 2 ,
    input: { color: 'white' }, // Change the input text color to white
	 textarea: { color: 'white' }, // Ensure textarea text is also white
    label: { color: 'white' }, // Change the label text color to white
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Change the border color to white
      },
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color to white when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color to white when focused
      },
    },
  }}
      />
    </Grid>


    {(projectTypes && projectTypes.length>0) && <Grid item xs={12}>
      <FormControlLabel
        control={<Checkbox   sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }} checked={selectStandardDescriptionManually} onChange={(e) => setSelectStandardDescriptionManually(e.target.checked)} />}
        label="Add your own Introduction"
      />
</Grid>}
{selectStandardDescriptionManually && (
  <>

    <Grid item xs={12} style={{ height: '300px', overflowY: 'auto' }}>
      <Grid container spacing={2}>
    <Grid item xs={12}>
        {projectTypes.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Checkbox   sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }}
              checked={selectedDescriptions.includes(item.description)}
              onChange={(e) => handleDescriptionSelectionChange(item, e.target.checked)}
            />
            {item.title} {/* Display summary or identifier of the portfolio item */}
          </Grid>
        ))}
      </Grid>
    </Grid>
	</Grid>
  </>
)}	

    <Grid item xs={12}>
      <FormControlLabel
        control={<Checkbox   sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }} checked={selectApproachManually} onChange={(e) => setSelectApproachManually(e.target.checked)} />}
        label="Type your Approach Manually"
      />
    </Grid>
{selectApproachManually && (
  <>


      <Grid container spacing={2}>
    <Grid item xs={12}>
<TextField
  fullWidth
  label="Type your approach (in less than 250 characters)"
  variant="outlined"
  value={selectedApproach}
  onChange={(e) => {
    if (e.target.value.length <= 250) {
      setSelectedApproach(e.target.value);
    }
  }}
  inputProps={{ maxLength: 250 }} // This visually limits the input but doesn't block pasting over the limit
  helperText={`${selectedApproach.length}/250 characters`}
          sx={{
				 mb: 2 ,
    input: { color: 'white' }, // Change the input text color to white
	 textarea: { color: 'white' }, // Ensure textarea text is also white
    label: { color: 'white' }, // Change the label text color to white
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Change the border color to white
      },
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color to white when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color to white when focused
      },
    },
  }}
/>

    </Grid>

      </Grid>
   
  </>
)}
{(filteredData && filteredData.length>0) && (
    <Grid item xs={12}>
      <FormControlLabel
        control={<Checkbox   sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }} checked={selectReferencesManually} onChange={(e) => setSelectReferencesManually(e.target.checked)} />}
        label="Select References Manually"
      />
    </Grid>
	)
}
    {/* Conditional rendering of Search and Portfolio Items Grid */}
{selectReferencesManually && (
  <>

    <Grid item xs={12} style={{ height: '300px', overflowY: 'auto' }}>
      <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField
        fullWidth
        label="Search References"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        	  sx={{
				  mb:2,
    input: { color: 'white' }, // Change the input text color to white
    label: { color: 'white' }, // Change the label text color to white
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Change the border color to white
      },
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color to white when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color to white when focused
      },
    },
  }}
      />
    </Grid>
        {filteredData.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Checkbox   sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }}
              checked={selectedReferences.includes(item.summary)}
              onChange={(e) => handleReferenceSelectionChange(item, e.target.checked)}
            />
            {item.summary} {/* Display summary or identifier of the portfolio item */}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </>
)}
    <Grid item xs={12}>
      <FormControlLabel
        control={<Checkbox   sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }} checked={useO1} onChange={(e) => setUseO1(e.target.checked)} />}
        label="Use the advanced O1 Model (longer wait until the proposal is ready)"
      />
    </Grid>

    {/* Generate Proposal Button */}
    <Grid item xs={12}>
<Button onClick={() => handleGenerateProposalManual(data.filter(function(v){return selectedReferences.includes(v.summary)}),selectedApproach,selectedDescriptions,useO1)} color="primary">
  Generate Proposal
</Button>

    </Grid>
  </Grid>

  {/* Dialog to display the proposal response */}
  {isResponseModalOpen && !isLoading && (
    <Dialog open={isResponseModalOpen} onClose={() => setIsResponseModalOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Generated Proposal</DialogTitle>
      <IconButton aria-label="close" onClick={() => setIsResponseModalOpen(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom style={{ whiteSpace: 'pre-wrap' }}>
          {proposalResponse}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => copyToClipboard(proposalResponse)} color="primary">
          Copy to Clipboard
        </Button>
      </DialogActions>
    </Dialog>
  )}
       <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar}   severity={snackbarMessage.includes('Failed') ? 'error' : 'success'}  sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
</Box>
)
};

export default GenerateProposalManual;
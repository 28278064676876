import React, { useState, useEffect,useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {Avatar,Drawer, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions,AppBar,Divider,Link as Link1, Toolbar, Typography, TextField, IconButton, Button, Box, Container, Grid, Card, CardContent, CardActions, Snackbar, Alert, Fab, CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveIcon from '@mui/icons-material/Save';
import { useTheme,createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PreloadVideo from './PreloadVideo';
import { firebase, auth, onAuthStateChanged,analytics,logEvent } from './firebase';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import tags from './tags2.json'
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh'; // Import the refresh icon
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation,Navigate } from 'react-router-dom';
import SubscriptionPage from './SubscriptionPage';
import YourPortfolio from './YourPortfolio';
import NewProjects from './NewProjects';
import GenerateProposalManual from './GenerateProposalManual';
import PaymentPage from './PaymentPage';
import StyledFirebaseAuth from './StyledFirebaseAuth';
import UpworkLinkInput from './UpworkLinkInput';
import LoadingScreen from './LoadingScreen';
import SettingsPage from './SettingsPage'; // Import SettingsPage
import AboutUsDrawer from './AboutUsDrawer'
import VideoPlayer from './VideoPlayer'
import { useNavigate } from 'react-router-dom';
import Slideshow from './Slideshow'; // Adjust the path if necessary
import TagSelector from './TagSelector'; // Import the TagSelector component
import useNoPendingRequests from './useNoPendingRequests'
const servergooglelink="https://us-central1-freelancemanager-f87dd.cloudfunctions.net/proposalmaker1"

const serverlink="https://earlybirdserver-kg6sdts6eq-uc.a.run.app";
//const serverlink="http://localhost:8080";
const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false, // Avoid redirects after sign-in.
  },
};


function calculateWeightedResult(arr) {
  const maxResult = 206; 
  const n = arr.length; 

  if (n === 0) return 0; 
  if (n === 1) return arr[0];

  
  const sortedArr = [...arr].sort((a, b) => b - a);

 
  const weights = sortedArr.map((_, index) => 1 - index * 0.05);

  
  let weightedSum = 0;
  for (let i = 0; i < n; i++) {
    weightedSum += sortedArr[i] * weights[i];
  }

  
  if (weightedSum > maxResult) {
   
    const scaleFactor = maxResult / weightedSum;
    weightedSum *= scaleFactor
  }

  return Math.floor(weightedSum);
}
const categoryValues=[11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]
const StripePricingTable = ({ pricingTableId, publishableKey }) => {
  useEffect(() => {
    // Dynamically load the Stripe Pricing Table script
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <stripe-pricing-table pricing-table-id={pricingTableId} publishable-key={publishableKey}></stripe-pricing-table>
  );
};
function bufferToHex(buffer) {
  return Array.from(new Uint8Array(buffer))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
}
async function encryptData(data, key) {
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);

  const encryptedData = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv
    },
    key,
    encodedData
  );

  // Combine iv and encrypted data into a single buffer
  const combinedBuffer = new Uint8Array(iv.length + encryptedData.byteLength);
  combinedBuffer.set(iv, 0);
  combinedBuffer.set(new Uint8Array(encryptedData), iv.length);

  return bufferToHex(combinedBuffer.buffer);
}
async function createEncodedKey(idToken, key) {
  const encoder = new TextEncoder();
  const extraKey="figr0figry"
  const keyMaterial = encoder.encode(idToken + key+extraKey);

  // Hash the key material to ensure it is 256 bits
  const hash = await crypto.subtle.digest('SHA-256', keyMaterial);

  // Import the hashed key material into a CryptoKey object
  return crypto.subtle.importKey(
    'raw',
    hash,
    { name: 'AES-GCM' },
    false,
    ['encrypt', 'decrypt']
  );
}


const copyToClipboard = async (textToCopy) => {
  // Check if clipboard API is available
  if (navigator.clipboard && window.isSecureContext) {
    try {
		
      await navigator.clipboard.writeText(textToCopy);
      console.log('Proposal copied to clipboard');
    } catch (error) {
      console.error('Failed to copy proposal with clipboard API:', error);
      copyToClipboardFallback(textToCopy);
    }
  } else {
    // Fallback for Safari on iOS
    copyToClipboardFallback(textToCopy);
  }
};

const copyToClipboardFallback = (textToCopy) => {
  // Create a temporary textarea
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};


function App() {
	  const elementRef = useRef(null);

  const [basicPlan,setBasicPlan]=useState(false);
  const [initEmail, setInitEmail] = useState('');
  const [tagLogged,setTagLogged]= useState(false);
  const [isGeneratingProposal,setIsGeneratingProposal]= useState(false);
  const [loadingOnce,setLoadingOnce] = useState(false);
  const [stripeId,setStripeId] = useState('');
  const [hasPaid,setHasPaid] = useState(false);
  const [infoLoaded,setInfoLoaded] = useState(false);
  const [authInitialized, setAuthInitialized] = useState(false);
  const [projects, setProjects] = useState([]);
  const [freelancerId, setFreelancerId] = useState('');
  const [showUpworkLinkInput, setShowUpworkLinkInput] = useState(false);
  const [showUpworkTagsInput, setShowUpworkTagsInput] = useState(false);
  const [upworkProfileLink, setUpworkProfileLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showProfileBuilding, setShowProfileBuilding] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [userID, setUserID] = useState('');
  const [userName, setUserName] = useState('');
  const [data, setData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
    const [emailAddress, setEmailAddress] = useState('');

  
  const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);

  const [selectedTags, setSelectedTags] = useState([]);

  const [tagOptions, setTagOptions] = useState([...tags.map(function(v){return v.prettyName})]); 
const [isDialogOpen, setIsDialogOpen] = useState(false);
const [selectedProject, setSelectedProject] = useState(null);
const [isGenerating, setIsGenerating] = useState(false);
const [proposalResponse, setProposalResponse] = useState('');
const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [projectTitle, setProjectTitle] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
 const [fixedPriceRange, setFixedPriceRange] = useState('');
const [hourlyRateRange, setHourlyRateRange] = useState('');
const [projectTypes,setProjectTypes] = useState([]);
const [openAIKeySetup,setOpenAIKeySetup] = useState(false);
const [customPrompt, setCustomPrompt] = useState('');
const [model, setModel] = useState('');
const [allowRefresh,setAllowRefresh]= useState(false);
const [skipped,setSkipped]= useState(false);
  const [resourcesReady, setResourcesReady] = useState(false);

 const resourcesLoaded = useNoPendingRequests(resourcesReady);

  useEffect(() => {
    // Simulate fetching or loading resources dynamically
    setTimeout(() => {
      console.log('Resources are returned, now starting to monitor network requests.');
      setResourcesReady(true); // Start monitoring after resources are added to the page
    }, 1000); // Simulate a delay in loading resources
  }, []);

const getKeyAndEncodeData = async (data,idToken) => {
let response="";
try {

  // Get key from the Firebase table
  // Send token to your backend via HTTPS
   response= await fetch(serverlink+'/keys'+'?idToken='+idToken,{headers:{idToken}});
  
   } catch (error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
};
const data1 = await response.json();
const key = data1.key;
const id= data1.id;

  // Encode the key with the ID token
  const encodedKey1 = await createEncodedKey(idToken,key);
  // Encode the data with encodedKey1

  const encryptedData = await encryptData(data, encodedKey1);
  return {data:encryptedData,id:id}
  // Send to backend
  //fetch('/secure-endpoint', {
  //  method: 'POST',
  //  headers: {
  //    'Content-Type': 'application/json',
  //    'Authorization': `Bearer ${idToken}`
  //  },
  //  body: JSON.stringify({ encryptedData, keyId })
  //});
};


 const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
const handleOpenDialog = (project) => {
  setSelectedProject(project);
  setIsDialogOpen(true);
};

const handleCloseDialog = () => {
  setIsDialogOpen(false);
  setSelectedProject(null); // Reset the selected project
};

const handleRefresh = async () => {
  // Fetch the updated projects from the server

 setIsLoading(true);
 const response = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/projects?tags='+selectedTags.join("|||").split(" ").join("+")+'&fixed='+fixedPriceRange+'&hourly='+hourlyRateRange+'&idToken='+idToken);
}).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});


    const data = await response.json();
    setProjects(data);
	setIsLoading(false);
};



const handleGenerateProposal = async () => {
  setIsLoading(true);
  setIsGeneratingProposal(true);
  //await fetchData();
  try {
    // Simulate a server request
 const response = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(servergooglelink, {
    method: 'POST', // or 'POST'
    headers: {
      'Content-Type': 'application/json',
    },
        body: JSON.stringify({ selectedProject: selectedProject,freelancerId:freelancerId,name:userName,idToken:idToken })
  })
}).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
		   setIsGeneratingProposal(false);
	   return "error";
});
console.log(response);
 if (response=="error") {
	 return 1;
 }
    if (response.ok) {
      const data = await response.json();
      setProposalResponse(data.proposalText); // Assume the server responds with proposal text
      setIsResponseModalOpen(true);
    } else {
	  setSnackbarMessage(await response.text());
	  setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
    }
  } catch (error) {
    setSnackbarMessage('Error occurred while generating proposal.');
	setOpenSnackbar(true);

    console.error('Error generating proposal:', error);
  } finally {
    setIsLoading(false);
	setIsGeneratingProposal(false);
  }
};


const handleGenerateProposalManual = async (references,approach,descriptions,useO1) => {
  setIsLoading(true);
  setIsGeneratingProposal(true);
  //await fetchData();
  try {
    // Simulate a server request
	
 const response = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(servergooglelink, {
    method: 'POST', // or 'POST'
    headers: {
      'Content-Type': 'application/json',
    },
        body: JSON.stringify({ useO1:useO1,selectedProject:{"Title":projectTitle,"Description":projectDescription},approach:(descriptions.length>0?(descriptions.join(". ")+approach):approach),references:references,freelancerId:freelancerId,name:userName,idToken:idToken })
  })
}).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	   setIsLoading(false);
	   setIsGeneratingProposal(false);
	   return "error";
});	
	 if (response=="error") {
	 return 1;
 }
    if (response.ok) {
      const data = await response.json();
      setProposalResponse(data.proposalText); // Assume the server responds with proposal text
      setIsResponseModalOpen(true);
    } else {
	  setSnackbarMessage(await response.text());
	  setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
    }
  } catch (error) {
    setSnackbarMessage('Error occurred while generating proposal.');
	setOpenSnackbar(true);

    console.error('Error generating proposal:', error);
  } finally {
    setIsLoading(false);
	setIsGeneratingProposal(false);
  }
};

  const signOut = async () => {
  await auth.signOut().then(async () => {
    setIsAuthReady(false);
    setIsSignedIn(false);
	setHasPaid(false);
	setLoadingOnce(false);
	//await fetchData()
  }).catch((error) => {
    // An error happened.
    console.error('Sign Out Error', error);
  });
};
    const saveProfiles = async () => {
    try {

 const response = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/user_profiles'+'?idToken='+idToken+'&userId='+userID+'&freelancerId='+(freelancerId?freelancerId:userID), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
		  'idToken':idToken
        },
        body: JSON.stringify({ profiles: data }),
      });
  
  }).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});


      

      if (response.ok) {
        setSnackbarMessage('Profiles saved successfully.');
		setOpenSnackbar(true);
      } else {
        setSnackbarMessage('Failed to save profiles.');
		setOpenSnackbar(true);
      }
    } catch (error) {
      console.error('Error saving profiles:', error);
      setSnackbarMessage('Error occurred while saving profiles.');
    }
    setOpenSnackbar(true);
  };

const fetchData = async () => {

  setIsLoading(true); // Start loading
  try {

 const user0Response = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/userIDs?idToken='+idToken+'&userId='+userID,{headers:{idToken}});
  
  }).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});

 const userResponse = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/userReadyIDs?idToken='+idToken+'&userId='+userID,{headers:{idToken}});
  
  }).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});
	

	const paymentResponse=await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
		  
		  return await fetch(serverlink+"/payment_status?idToken="+idToken,{headers:{idToken}});
		  
	  })
	
	   if (paymentResponse.ok) {
		   console.log("has paid")
	    setHasPaid(true);
		setInfoLoaded(true);
		let stripeIdtemp=await paymentResponse.json();
		setStripeId(stripeIdtemp.customer);
      } else {
		  console.log("has not paid")
	  }
	  if (firebase.auth().currentUser && firebase.auth().currentUser.email) {
		  	    setEmailAddress(firebase.auth().currentUser.email);

	  }
	 console.log("check user");
    if (!user0Response.ok && !userResponse.ok) { // Check if the user ID was not found
      throw new Error('UserID not found. Please provide your Upwork profile link.');
    } else if (!userResponse.ok) {
		
	
      setShowProfileBuilding(true); // Show profile building message
      return; // Exit the function early		
	}
	console.log("user checked");
	const userJsonData = await userResponse.json();
	setShowUpworkLinkInput(false);
	setShowUpworkTagsInput(false);
    console.log(userJsonData);
    if (userJsonData.basic) {
		setBasicPlan(true);
	}
	setFreelancerId(userJsonData.id);
let refreshed=userJsonData.refreshed
	setAllowRefresh((!refreshed || (refreshed && new Date().getTime()-Number(refreshed)>30*24*60*60000)))
	console.log("userJsonData.skipped:"+String(userJsonData.skipped))
	setSkipped(userJsonData.skipped || false)
		if (!("tags" in userJsonData) || userJsonData.tags.length==0) {
      setShowUpworkTagsInput(true); // Show profile building message
      return; // Exit the function early		
	}
    console.log("setSelectedTags")
	console.log(userJsonData.tags)
	setSelectedTags(userJsonData.tags);
	setHourlyRateRange(userJsonData.hourly);
	setFixedPriceRange(userJsonData.fixed);
    setProjectTypes(userJsonData.projecttypes || []);	
	setOpenAIKeySetup(userJsonData.openAIKeySetup || false);
	setCustomPrompt(userJsonData.customPrompt || '');
	setModel(userJsonData.model || 'gpt-4o')
	 const response = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/user_profiles'+'?idToken='+idToken+'&userId='+userID+'&freelancerId='+userJsonData.id,{headers:{idToken}});
  
  }).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});
	
	
   
	if (!response.ok) { // Check if the profiles are still being prepared
      setShowProfileBuilding(true); // Show profile building message
      return; // Exit the function early
    }
    const jsonData = await response.json();
    const jsonDataWithFavorites = jsonData.map(item => ({
      ...item,
      favorite: item.favorite || false // Ensure the favorite key exists
    }));
    setData(jsonDataWithFavorites);
	
	
   
  const fetchProjects = async (tags,fixed,hourly) => {
    // Fetch projects from your server
	//console.log("selectedTags");
	//console.log(selectedTags);

	 const response = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/projects?tags='+tags.join("|||").split(" ").join("+")+'&fixed='+fixed+'&hourly='+hourly+'&idToken='+idToken,{headers:{idToken}});
  
  }).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});
	
    //const response = await fetch(serverlink+'/projects?tags='+tags.join("|||").split(" ").join("+"));
    const data = await response.json();
    setProjects(data);
  };

  await fetchProjects(userJsonData.tags,userJsonData.fixed,userJsonData.hourly);
	
  } catch (error) {
    console.error('Error fetching initial data:', error);
    setShowUpworkLinkInput(true); // Show the input box for Upwork link
    setErrorMessage(error.message); // Optional: Set an error message to display
  } finally {
    setIsLoading(false); // Stop loading whether there was an error or not
	if (!loadingOnce) {
	setLoadingOnce(true);
    }
  }
};
  
const skipProfileLink = async () => {
  setIsLoading(true);
  setOpenSnackbar(false); // Close snackbar first, in case it was already open
  try {
    // Indicate that the user is ready to proceed without an Upwork profile link

	 const readyResponse = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/userReadyIDs?idToken='+idToken+'&userId='+userID+'&skipped=true',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
		'idToken':idToken
      },
      body: JSON.stringify({ "id": userID }),
    });
  
  }).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});
    // Proceed to create empty profiles or handle the user's decision to skip

	 const profileResponse = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/user_profiles'+'?idToken='+idToken+'&userId='+userID+'&freelancerId='+(freelancerId?freelancerId:userID),{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
		'idToken':idToken
      },
      body: JSON.stringify({ profiles: [] }),
    });
  
  }).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});
    if (readyResponse.ok && profileResponse.ok) {
      setSnackbarMessage('Profiles updated successfully. You may proceed.');
	  setOpenSnackbar(true);
    } else {
      // If either of the responses is not OK, handle it as a failure
      setSnackbarMessage('Failed to update profiles. Please try again.');
	  setOpenSnackbar(true);
    }
  } catch (error) {
    console.error('Error during profile update:', error);
    setSnackbarMessage('An error occurred while updating profiles.');
	setOpenSnackbar(true);
	setIsLoading(false);
  }
  setIsLoading(false);
  setOpenSnackbar(true); // Show snackbar with the result message
  await fetchData();
};
const saveProfileLink = async () => {
  try {
	  setIsLoading(true);
  let link=upworkProfileLink;
	  console.log("link");
	  console.log(link);
    const upworkProfilePattern = /^https:\/\/www\.upwork\.com\/freelancers\/([^\/]+)/;
    const match = upworkProfileLink.toLowerCase().match(upworkProfilePattern);
    console.log(match);
    if (!match) {
      // If the link doesn't match the pattern, display an error and exit the function early
      setSnackbarMessage('Invalid Upwork profile link. Please check the link and try again.');
      setOpenSnackbar(true);
      return;
    }

    // Extract the Upwork ID from the matched pattern
    const uwID = match[1].split("?")[0];


 const response = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  return await fetch(serverlink+'/userIds?idToken='+idToken+'&userId='+uwID,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
		'idToken':idToken
      },
     body: JSON.stringify({ id: userID }),
    });
  
  }).catch(function(error) {
	  setIsLoading(false);
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});
      if (response.ok) {
        setSnackbarMessage('User saved successfully.');
		setOpenSnackbar(true);
      } else {
        setSnackbarMessage('Failed to save user.');
		setOpenSnackbar(true);
      }

  } catch (error) {
    console.error('Error saving user:', error);
    setSnackbarMessage('Error occurred while saving user.');
    setOpenSnackbar(true);
	setIsLoading(false);
  }
  setIsLoading(false);
  await fetchData();
};
const saveTags = async (deactivateAcc,deleteApiKey,openAIKey,refreshProfile,profileUrl) => {
  try {
	  let uwID="";
	  if (profileUrl) {
		   const upworkProfilePattern = /^https:\/\/www\.upwork\.com\/freelancers\/([^\/]+)/;
    const match = profileUrl.toLowerCase().match(upworkProfilePattern);
    if (!match) {
      // If the link doesn't match the pattern, display an error and exit the function early
      throw ('Invalid Upwork profile link. Please check the link and try again.');
      
    }

    // Extract the Upwork ID from the matched pattern
    uwID = match[1].split("?")[0];
	  }
  let tags=selectedTags;
    if (!tags || tags.length==0) {
      // If the link doesn't match the pattern, display an error and exit the function early
      setSnackbarMessage('You need to select at least one tag.');
      setOpenSnackbar(true);
      return;
    }
   let fixedPrice=fixedPriceRange;
   let hourlyPrice=hourlyRateRange;
   if (!fixedPrice) {
      setSnackbarMessage('You need to select a fixed price lower limit.');
      setOpenSnackbar(true);
      return;	   
   }
   if (!hourlyPrice) {
      setSnackbarMessage('You need to select an hourly rate lower limit.');
      setOpenSnackbar(true);
      return;	   
   }
    // Extract the Upwork ID from the matched pattern


//tagOptions

 const readyResponse = await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  
  //openAIKey
  let encodeddt={id:"",data:""}
  if (openAIKey) {
  encodeddt=await getKeyAndEncodeData(openAIKey,idToken);
  }

  return await fetch(serverlink+'/userReadyIDs?idToken='+idToken+'&userId='+freelancerId,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
		'idToken':idToken
      },
      body: JSON.stringify({ refreshProfile:refreshProfile,model:model,customPrompt:customPrompt,keydata:encodeddt.data,keyid:encodeddt.id,deleteApiKey:deleteApiKey,projecttypes:(projectTypes||[]),"id": userID,"tags":tags,"fixed":fixedPrice,"hourly":hourlyPrice,"deactivateAcc":deactivateAcc,"profileUrl":uwID }),
    });
  
  }).catch(function(error) {
setSnackbarMessage(error);
setOpenSnackbar(true);
      console.error('Server responded with non-OK status');
	  	   setIsLoading(false);
	   return "error";
});
if (openAIKey) {
	 await signOut();
}
      if (readyResponse.ok) {
        setSnackbarMessage('Settings updated successfully.');
		setOpenSnackbar(true);
		setShowUpworkTagsInput(false);
		await fetchData();
      } else {
        setSnackbarMessage('Failed to update settings.');
		setOpenSnackbar(true);
      }
  
  } catch (error) {
    console.error('Error saving tags:', error);
    setSnackbarMessage('Error occurred while saving tags. '+error);
    setOpenSnackbar(true);
  }
  if (deactivateAcc) {
	  await signOut();
	  return 1;
  }
  await fetchData();
};
const manageKeywords = async () => {
	setShowUpworkTagsInput(true);
}

 useEffect(() => {

    const unregisterAuthObserver = onAuthStateChanged(auth,(user) => {

		setAuthInitialized(true);
		if (user) {
			
			//console.log(user)
      setIsSignedIn(!!user);
	  setUserID(user.uid);
	  setUserName(user.displayName);
      setIsAuthReady(true); // Set auth readiness to true when the check is complete
		}
    });
		// throw "here"
    return () => unregisterAuthObserver(); // Cleanup subscription on unmount
  }, []);

  // Fetch data when the user is signed in
  useEffect(() => {
 if (!isSignedIn || !isAuthReady) {
      return;
    }



    fetchData();
  }, [isSignedIn,isAuthReady]); // Only re-run the effect if isSignedIn changes

  // UI for when the user is not signed in

  const toggleFavorite = (index) => {
    const newData = [...data];
    newData[index].favorite = !newData[index].favorite;
    setData(newData);
  };

  const updateField = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };
const isValidPriceInput = (value) => {
  // Allow any input that starts with the letter "a" or is a digit (to eventually form "Any" or a number)
  return value === '' || /^\d*$/.test(value);
};


  const addNewItem = () => {
    setData([{ id: "", summary: "", link: "", favorite: false },...data]);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const deleteItem = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };
  const onPaymentVerification = async (sessionId) => {
  console.log("seesionid: "+sessionId);
  if (sessionId) {
	  
	  let response=await firebase.auth().currentUser.getIdToken(true).then(async function(idToken) {
		  
		  return await fetch(serverlink+"/payment_session?sessionID="+sessionId+"&idToken="+idToken,{headers:{idToken}});
		  
	  })
	  
	   if (response.ok) {
		   //console.log(await response.text());
		   
		
    // Function to add the gtag script to the page
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16492661169/MNJUCJafyMwZELGTqLg9',
        'value': 1.0,
        'currency': 'EUR'
      });
    

    // Execute the gtag function
//skipProfileLink
const subData = await response.json();

	    setHasPaid(true);
		setInfoLoaded(true);

if (subData.basic) {
	  setBasicPlan(true);
	  setShowUpworkTagsInput(true);
	await skipProfileLink();

}
	    return true;
		
      } else {
        setSnackbarMessage(await response.text());
		setOpenSnackbar(true);
      }
	  
	  

  }
  return false;
  }
  const getFullLink = (id) => {
    return id.startsWith('~') ? `https://www.upwork.com/jobs/${id}` : id;
  };

          if ( !authInitialized) {
			return (
			<div>
			        <LoadingScreen/>
			</div>
			);
		}

        if (!isAuthReady) {
			logEvent(analytics,"sign in page loaded");
const slides = [
  {
    src: 'HOW IT WORKS.png',
    title: '',
  }
];

 return   (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 4,
        color: '#fff',
		beckgroundSize:'cover',
		opacity:(resourcesReady?1:0),
		transition: 'opacity 1s ease-in'
      }}
    >
	    <Box
      sx={{
        display: 'flex',           // Set parent to flexbox
        flexDirection: 'column',   // Stack children vertically
        alignItems: 'center',      // Center children horizontally
        justifyContent: 'center',  // Center children vertically
        backgroundColor: 'rgba(0,0,0,0)' // Optional background color
      }}
    >
	  <Box
  component="img"
  src="logo.png"
  alt="Logo"
  sx={{
    m: 1,
    width: 150,
    height: "auto",
	alignItems: 'center', // Centers children horizontally
        justifyContent: 'center', // Centers children vertically
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0)',
    borderRadius: '8px', // Optional: If you want rounded corners, remove if not needed
	display:"flex",
	flexDirection:"column"
  }}
/>
</Box>
	<AboutUsDrawer elementRef={elementRef}/>
      {/* Header Section */}
      <Box
        sx={{
			alignItems: 'center', // Centers children horizontally
        justifyContent: 'center', // Centers children vertically
          textAlign: 'center',
          mb: 4,
          p: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)', // Frosted glass effect for futuristic look
          borderRadius: '12px',
        }}
      >
	   
    <Box
      sx={{
        display: 'flex',           // Set parent to flexbox
        flexDirection: 'column',   // Stack children vertically
        alignItems: 'center',      // Center children horizontally
        justifyContent: 'center',  // Center children vertically
        backgroundColor: 'rgba(0,0,0,0)' // Optional background color
      }}
    >


<Box
  component="img"
  src="simplified.png" // Replace with the path to your image
  alt=""
  sx={{
	  	display:"flex",
	flexDirection:"column",
	alignItems: 'center', // Centers children horizontally
        justifyContent: 'center', // Centers children vertically
	opacity:1,
    width: 'auto', // Ensures the image takes the full width of its container
    height: '200px', // Maintains the aspect ratio of the image
    mt: 2, // Adds some margin-top to separate the image from the text above
				   borderRadius: '16px', // Rounded corners for a modern look
    backgroundColor: 'rgba(255, 255, 255, 0)', // Semi-transparent background for glassmorphism effect
   
  }}
/>
</Box>
        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            mt: 2,
            mb: 4,
          }}
        >
          Elevate your productivity with personalized guidance from Top Rated Plus freelancers on Upwork, and enjoy instant job notifications, advanced filtering, and AI-generated proposals!
        </Typography>
			  	  <Box
  component="img"
  src="tl.webp"
  alt="Freelance"
  sx={{
    m: 1,
    width: 160,
    height: "auto",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0)',
    borderRadius: '8px', // Optional: If you want rounded corners, remove if not needed
  }}
/>
      </Box>
 <VideoPlayer src={"howitworks.m4v"}/>
	  <Box
  component="img"
  src="unlock.png"
  alt=""
  sx={{
    m: 1,
	width: { xs: '100vw', md: 'auto' },
	height: { xs: 'auto', md: '100vh' },
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0)',
  }}
/>
      {/* Slideshow and Login Section */}

        {/* Futuristic Slideshow Section */}
		        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent background for the form
            backdropFilter: 'blur(10px)', // Frosted glass effect
            padding: 4,
            borderRadius: '16px',
            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.5)',
            width: { xs: '100%', md: '100%' },
    border: '1px solid rgba(255, 255, 255, 0.2)', // Light border for subtle definition
    transition: 'all 0.3s ease', // Smooth transition for hover effects
    '&:hover': {
      boxShadow: '0 6px 50px rgba(0, 0, 0, 0.7)', // More intense shadow on hover
    },
	    overflow: 'hidden', // Ensures content doesn't spill out of the rounded corners
            flex: 1,
			}}
        >
<Box sx={{ p: 3 }}>
  <Typography variant="h6" sx={{ mb: 2 }}>
    Choose your skills to discover the number of daily leads you can expect!
  </Typography>
  
  <TagSelector
    tagOptions={tagOptions}
    selectedTags={selectedTags}
    setSelectedTags={setSelectedTags}
    expanded={true}
    titleHidden={true}
    sx={{ width: "100%" }}
  />
  
  <Typography variant="body1">
    {[
      calculateWeightedResult(
        selectedTags
          .map(function (v) {
            return tags.filter(function (v1) {
              return v1.prettyName === v;
            });
          })
          .map(function (v) {
            return categoryValues[v[0].category];
          })
      )
    ].map(function (v) {
      if (v > 0) {
		  if (!tagLogged) {
			        console.log("tags selected");
		  			logEvent(analytics,"tags selected");
					setTagLogged(true)
          }
        return (
          <>
            With your selected skills, you can expect to receive on average{' '}<br/>
            <Typography component="span" sx={{ fontSize:"2em",fontWeight: 'bold' }}>
              {v}
            </Typography>
			<br/>
            {' '}leads per day. Add more skills to maximize your opportunities!
          </>
        );
      } else {
        return "";
      }
    })[0]}
  </Typography>
</Box>

</Box>
        {/* Login Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent background for the form
            backdropFilter: 'blur(10px)', // Frosted glass effect
            padding: 4,
            borderRadius: '16px',
            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.5)',
            width: { xs: '100%', md: '40%' },
    border: '1px solid rgba(255, 255, 255, 0.2)', // Light border for subtle definition
    transition: 'all 0.3s ease', // Smooth transition for hover effects
    '&:hover': {
      boxShadow: '0 6px 50px rgba(0, 0, 0, 0.7)', // More intense shadow on hover
    },
	    overflow: 'hidden', // Ensures content doesn't spill out of the rounded corners
            flex: 1,
			}}
        >

	  <Box
	 ref={elementRef} 
  component="img"
  src="discover.png"
  alt="Discover"
  sx={{
    m: 1,
    width: 160,
    height: "auto",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0)',
    borderRadius: '8px', // Optional: If you want rounded corners, remove if not needed
  }}
/>
<PreloadVideo vdpath={"freetrial.m4v"}/>
          <StyledFirebaseAuth  />
        </Box>
    </Container>
  );




  }
  
          if (!loadingOnce && !resourcesReady) {
			return (
			<div>
			        <LoadingScreen/>
			</div>
			);
		}
  
    const drawerContent = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: '#333',
      color: 'white', height: '100%' }}>
      <List>
	  {!basicPlan && <ListItem button component={Link} sx={{ color: 'white' }} to="/">
          <ListItemText primary="Your Portfolio" />
	  </ListItem>}
        <ListItem button component={Link} sx={{ color: 'white' }} to="/settings">
          <ListItemText primary="Settings" />
        </ListItem>
        {!basicPlan && <ListItem button component={Link} sx={{ color: 'white' }} to="/generate-proposal">
          <ListItemText primary="Generate Proposal" />
        </ListItem>}

<ListItem button component={Link} sx={{ color: 'white' }} to="/subscription">
  <ListItemText primary="Subscription" />
</ListItem>

        <ListItem button sx={{ color: 'white' }} onClick={signOut}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Router>
 
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
sx={{
          background: 'linear-gradient(90deg, rgba(33,150,243,1) 0%, rgba(156,39,176,1) 100%)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
          borderRadius: '12px',
        }}
		position="static">
		{(!showUpworkLinkInput && hasPaid) && <Toolbar>
            {isMobile ? (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute', // Center logo in the AppBar
            left: '50%',
            transform: 'translateX(-50%)', // Adjust for perfect centering
          }}
        >
          <img
            src="logo.png"
            alt="Logo"
            style={{ width: '100px' }} // Set the width of the logo
          />
        </Box>
      </div>
            ) : (
              // Desktop menu items
              <>
			      <Box
      sx={{
        display: 'flex',
        alignItems: 'center', // Center logo vertically
        width: '150px',
      }}
    >
      <img
        src={"logo.png"}
        alt="Logo"
        style={{ width: '100px' }} // Set the width of the logo
      />
    </Box>
                {!basicPlan && <Button color="inherit" component={Link} to="/">Your Portfolio</Button>}

                <Button color="inherit" component={Link} to="/settings">Settings</Button>
				{!basicPlan && <Button color="inherit" component={Link} to="/generate-proposal">Generate Proposal</Button>}
				<Button color="inherit" component={Link} to="/subscription">Subscription</Button>
                <Button color="inherit" onClick={signOut}>Logout</Button>
              </>
            )}
		</Toolbar>}
        </AppBar>
        {(!showUpworkLinkInput) && <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }} // Better open performance on mobile.
        >
          {drawerContent}
</Drawer>}
		
      <Routes>
	  {!basicPlan && <Route path="/" element={<YourPortfolio data={data} isMobile={isMobile} toggleFavorite={toggleFavorite} updateField={updateField} addNewItem={addNewItem} deleteItem={deleteItem} getFullLink={getFullLink} saveProfiles={saveProfiles} snackbarMessage={snackbarMessage} openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} isLoading={isLoading} showUpworkLinkInput={showUpworkLinkInput} hasPaid={hasPaid} showProfileBuilding={showProfileBuilding} showUpworkTagsInput={showUpworkTagsInput} basicPlan={basicPlan} infoLoaded={infoLoaded} />} />}
		  {basicPlan && <Route path="/" element={        <SettingsPage
		     projectTypes={projectTypes}
            setProjectTypes={setProjectTypes}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            fixedPriceRange={fixedPriceRange}
            setFixedPriceRange={setFixedPriceRange}
            hourlyRateRange={hourlyRateRange}
            setHourlyRateRange={setHourlyRateRange}
            tagOptions={tagOptions}
            saveTags={saveTags}
			isLoading={isLoading}
			  openSnackbar={openSnackbar}
handleCloseSnackbar={handleCloseSnackbar}
snackbarMessage={snackbarMessage}
showUpworkLinkInput={showUpworkLinkInput} hasPaid={hasPaid} showProfileBuilding={showProfileBuilding} openAIKeySetup={openAIKeySetup} customPrompt={customPrompt} setCustomPrompt={setCustomPrompt} model={model} setModel={setModel} allowRefresh={allowRefresh} skipped={skipped} basicPlan={basicPlan} infoLoaded={infoLoaded}
		/>} />}

	{!basicPlan && <Route path="/generate-proposal" element={<GenerateProposalManual projectTypes={projectTypes} data={data} currentUser={firebase.auth().currentUser} serverUrl={serverlink} isGeneratingProposal={isGeneratingProposal} handleGenerateProposalManual={handleGenerateProposalManual} proposalResponse={proposalResponse} isResponseModalOpen={isResponseModalOpen} setIsResponseModalOpen={setIsResponseModalOpen} projectTitle={projectTitle} setProjectTitle={setProjectTitle} projectDescription={projectDescription} setProjectDescription={setProjectDescription} copyToClipboard={copyToClipboard} isLoading={isLoading} showUpworkLinkInput={showUpworkLinkInput} hasPaid={hasPaid} showProfileBuilding={showProfileBuilding} showUpworkTagsInput={showUpworkTagsInput} snackbarMessage={snackbarMessage} openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} basicPlan={basicPlan} infoLoaded={infoLoaded} />} />}
        <Route path="/settings" element={
          <SettingsPage
		     projectTypes={projectTypes}
            setProjectTypes={setProjectTypes}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            fixedPriceRange={fixedPriceRange}
            setFixedPriceRange={setFixedPriceRange}
            hourlyRateRange={hourlyRateRange}
            setHourlyRateRange={setHourlyRateRange}
            tagOptions={tagOptions}
            saveTags={saveTags}
			isLoading={isLoading}
			  openSnackbar={openSnackbar}
handleCloseSnackbar={handleCloseSnackbar}
snackbarMessage={snackbarMessage}
showUpworkLinkInput={showUpworkLinkInput} hasPaid={hasPaid} showProfileBuilding={showProfileBuilding} openAIKeySetup={openAIKeySetup} customPrompt={customPrompt} setCustomPrompt={setCustomPrompt} model={model} setModel={setModel} allowRefresh={allowRefresh} skipped={skipped} basicPlan={basicPlan} infoLoaded={infoLoaded}
		/>} />
        <Route path="/payment" element={<PaymentPage onPaymentVerification={onPaymentVerification} hasPaid={hasPaid} snackbarMessage={snackbarMessage} openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} emailAddress={emailAddress} initEmail={initEmail} setInitEmail={setInitEmail} basicPlan={basicPlan} signOut={signOut}/>} />
        
        {showProfileBuilding && !basicPlan && <Route path="/building-profile" element={      <div> <Box
  sx={{
    display: 'flex', // Enables flexbox
    justifyContent: 'center', // Centers content horizontally
    alignItems: 'center', // Centers content vertically
    height: {
          xs: 'auto',   // Automatic height to maintain aspect ratio on small screens
          sm: '100vh',  // Full viewport height on larger screens
        },
	margin:"0px"
  }}
>
    <Box
  component="img"
      src="profileAnalyzed.png"
  alt=""
  sx={{
	opacity:1,
        width: {
          xs: '100vw',  // Full width on small screens (mobile)
          sm: 'auto',   // Automatic width on larger screens
        },
        height: {
          xs: 'auto',   // Automatic height to maintain aspect ratio on small screens
          sm: '100vh',  // Full viewport height on larger screens
        },
    mt: 2, // Adds some margin-top to separate the image from the text above
				   borderRadius: '16px', // Rounded corners for a modern look
    backgroundColor: 'rgba(255, 255, 255, 0)', // Semi-transparent background for glassmorphism effect
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.5)', // Soft shadow for depth
    backdropFilter: 'blur(10px)', // Blur effect for a frosted glass look
    border: '1px solid rgba(255, 255, 255, 0.2)', // Light border for subtle definition
    transition: 'all 0.3s ease', // Smooth transition for hover effects
    '&:hover': {
      boxShadow: '0 6px 50px rgba(0, 0, 0, 0.7)', // More intense shadow on hover
    }
  }}
/>
		</Box></div>} />}
        {!basicPlan  && showUpworkLinkInput && <Route path="/upwork-link" element={<UpworkLinkInput isLoading={isLoading} setUpworkProfileLink={setUpworkProfileLink} upworkProfileLink={upworkProfileLink} saveProfileLink={saveProfileLink} skipProfileLink={skipProfileLink} hasPaid={hasPaid} showProfileBuilding={showProfileBuilding} showUpworkTagsInput={showUpworkTagsInput} emailAddress={emailAddress} basicPlan={basicPlan} />} />}
        {isLoading && isAuthReady && <Route path="/loading" element={<LoadingScreen />} />}
      // Inside your Router component in App.js or where you define your routes
	  <Route path="/subscription" element={<SubscriptionPage currentUser={firebase.auth().currentUser} userID={userID} serverUrl={serverlink} customerId={stripeId} showUpworkTagsInput={showUpworkTagsInput} infoLoaded={infoLoaded}/>} />
        <Route path="*" element={<Navigate to="/" replace />} />

	  </Routes>
	  </Box>
    </Router>
  );
  
}

export default App;
